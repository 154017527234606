import React from "react";
import ReactDOM from "react-dom";
import "./assets/sass/style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Simple Error Boundary component to catch errors
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render shows fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error captured in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// Service worker registration for offline support and faster loading
// Change serviceWorker.unregister() to serviceWorker.register() below if you want your app to work offline
// Be aware of potential caveats: https://bit.ly/CRA-PWA
serviceWorker.unregister();
