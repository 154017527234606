import React from "react";

function HomeAboutFeatures() {
  return (
    <div id="features" className="wrapper spacer p-top-xl">
      <div className="row gutter-width-md with-pb-lg">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h2>Na čem si zakládáme</h2>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h4>Kvalita</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam
              quis quam. Pellentesque habitant morbi tristique senectus et netus
              et malesuada fames ac turpis egestas.
            </p>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="feature-item">
            <h4>Pečlivost</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam
              quis quam. Pellentesque habitant morbi tristique senectus et netus
              et malesuada fames ac turpis egestas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAboutFeatures;
