import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import HomeHeader from "../blocks/header/HomeHeader";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import HomeInfo from "../blocks/home/HomeInfo";
import AboutsUs from "../blocks/home/AboutsUs";
import HomeServices from "../blocks/home/HomeServices";

import OurTeam from "../blocks/home/OurTeam";
import HomeNews from "../blocks/home/HomeNews";

import ContactsUs from "../blocks/home/ContactsUs";
import GoogleMaps from "../blocks/maps/GoogleMaps";

const Home = () => {
  document.body.classList.add("home");
  document.body.classList.add("header-absolute-true");
  document.body.classList.add("header-fixed-true");

  return (
    <Fragment>
      <Loading />

      <HomeHeader />

      <main id="main" className="site-main content-no-spacing">
        <div className="content">
          <div className="clearfix">
            <PageTitleHome />

            <HomeInfo />

            <AboutsUs />

            <HomeServices />

            <OurTeam />

            {/* <Testimonials /> */}

            <HomeNews />

            <ContactsUs />

            <GoogleMaps />
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Home;
