import React from "react";

const HeaderMenuPrimary = () => {
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li className="nav-item">
          <a title="About Us" href={process.env.PUBLIC_URL + "/#about-us"}>
            O nás
          </a>
        </li>

        <li className="nav-item">
          <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
            Služby
          </a>
        </li>

        <li className="nav-item">
          <a title="Our Teams" href={process.env.PUBLIC_URL + "/#our-team"}>
            Náš tým
          </a>
        </li>

        <li
          className={
            "nav-item " +
            (window.location.pathname.includes("/news") ||
            window.location.pathname.includes("/news-single-page")
              ? "current-nav-item"
              : "")
          }
        >
          <a title="News" href={process.env.PUBLIC_URL + "/news"}>
            Novinky
          </a>
        </li>

        <li className="nav-item">
          <a title="Contacts" href={process.env.PUBLIC_URL + "/#contacts"}>
            Kontakt
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderMenuPrimary;
