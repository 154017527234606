import React from "react";

const Maps = () => {
  return (
    <div style={{ width: "100%", height: "450px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81701.70267937875!2d15.758571980879353!3d50.21389019248954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c2b327674a559%3A0xe4f22f37295b6866!2zQsOtbMOhIHbEm8W-!5e0!3m2!1scs!2sus!4v1725651760839!5m2!1scs!2sus"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default Maps;
