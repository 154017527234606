import React from "react";
import { Link } from "react-scroll";

const HomeInfo = () => {
  return (
    <section id="information" className="block">
      <div className="wrapper">
        <div className="row no-gutters information-items">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-1">
              <div className="information-icon">
                <i className="hosco-icon-call-2"></i>
              </div>

              <h4 className="information-t-head">V případě akutního případu</h4>

              <div className="information-description">
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis.
                </p>
              </div>

              <div className="information-btn">
                <a
                  title="Call us now"
                  className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase"
                  href="tel:+43253312523"
                >
                  Volejte zde
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-2 information-icon-margin">
              <div className="information-icon">
                <i className="hosco-icon-dental-care"></i>
              </div>

              <h4 className="information-t-head">Kde nás najdete?</h4>

              <div className="information-description">
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis.
                </p>
              </div>

              <div className="information-btn">
                <Link
                  to="google-maps"
                  title="See on map"
                  className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id"
                  href="#google-maps"
                >
                  Zobrazit na Google Maps
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="information information-color-3">
              <div className="information-icon">
                <i className="hosco-icon-calendar"></i>
              </div>

              <h4 className="information-t-head">Otevírací doba</h4>

              <div className="information-description">
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Po - Pá:</span>
                    <span className="text-right">8:00 - 17:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Sobota:</span>
                    <span className="text-right">9:00 - 12:00</span>
                  </span>
                </p>
                <p>
                  <span className="d-flex justify-content-between flex-wrap">
                    <span>Neděle:</span>
                    <span className="text-right">Zavřeno</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeInfo;
