import React, { Component } from "react";
import { Link } from "react-scroll";

class HomeHeaderMenuPrimary extends Component {
  constructor() {
    super();
    this.state = {
      pushed: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ pushed: true }, () => {
      setTimeout(() => this.setState({ pushed: false }), 800); // Timeout to reset the pushed state
    });
  }

  render() {
    return (
      <nav className="menu-primary">
        <ul className="nav">
          <li id="nav-about" className="nav-item">
            <Link
              onClick={this.handleClick}
              title="About Us"
              to="about-us"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
            >
              O nás
            </Link>
          </li>

          <li id="nav-services" className="nav-item">
            <Link
              onClick={this.handleClick}
              title="Services"
              to="services"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
            >
              Naše služby
            </Link>
          </li>

          <li id="nav-teams" className="nav-item">
            <Link
              onClick={this.handleClick}
              title="Our Teams"
              to="our-team"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
            >
              Náš tým
            </Link>
          </li>

          <li id="nav-news" className="nav-item">
            <Link
              onClick={this.handleClick}
              title="News"
              to="news"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
            >
              Novinky
            </Link>
          </li>

          <li id="nav-contacts" className="nav-item">
            <Link
              onClick={this.handleClick}
              title="Contacts"
              to="contacts"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
            >
              Kontakt
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default HomeHeaderMenuPrimary;
