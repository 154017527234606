import React from "react";

const HomeAboutImg = () => {
  return (
    <div id="about-img" className="col-md-12 pt-5 ">
      <img src="assets/img/bg/bg_second.jpg" className="about-img" alt="IMG" />
    </div>
  );
};

export default HomeAboutImg;
